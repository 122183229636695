import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/**
 * Este servicio permite manejar el overlay de bloqueo o carga de elementos para el
 * elemento de sidenav-content.
 */
@Injectable()
export class SidebarService {

	private navigationBarsChangeEmmiter: Subject<boolean>;

	constructor() {
		this.navigationBarsChangeEmmiter = new Subject();
	}

	/**
	 * Este método bloquea el area de navegación del sidebar y añade un efecto.
	 */
	showLoading(): void {
		const loadingOverlay: HTMLElement | null = document.getElementById('loadingOverlay');
		const sidenavMainContent: HTMLElement | null = document.getElementById('sidenavMainContent');

		if (loadingOverlay) loadingOverlay.style.display = 'block';
		if (sidenavMainContent) sidenavMainContent.style.filter = 'blur(2px)';
	}

	/**
	 * Este método desbloquea el area de navegación del sidebar.
	 */
	hideLoading(): void {
		const loadingOverlay: HTMLElement | null = document.getElementById('loadingOverlay');
		const sidenavMainContent: HTMLElement | null = document.getElementById('sidenavMainContent');

		if (loadingOverlay) loadingOverlay.style.display = 'none';
		if (sidenavMainContent) sidenavMainContent.style.filter = 'none';
	}

}