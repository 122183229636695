import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { ReservationModel, URL_PROVIDER_ORIGINS } from 'src/app/shared/model/reservation.model';
import { ReservationDiscardModel } from 'src/app/shared/model/reservationDiscard.model';
import { HttpClientWrapperService } from './http-client-wrapper.service';
import { HttpModule } from './http.module';

@Injectable({ providedIn: HttpModule })
export class ReservationService {
  private API_PATH = 'person/v1.0';
  private ENTITY_PATH = 'reservations';
  //Contendrá el path y los params de las ads
  private urlOrigin$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  //Contiene el identity provider de las ads
  private urlProviderOrigin$: BehaviorSubject<string> = new BehaviorSubject<string>(URL_PROVIDER_ORIGINS.DIRECTO);

  /**
   * Tiempo en minutos por el que se considera que una reserva está expirada.
   */
  private static EXPIRED_MINUTES = 20;

  constructor(private httpClient: HttpClientWrapperService) {}

  /**
   * Obtener reservas expiradas dado el código de curso y sede.
   * Una reserva se considera expirada cuando transcurre el tiempo en minutos indidcado
   * en EXPIRED_MINUTES desde su creación.
   *
   * @param headquarterCode código de sede.
   * @param courseCode código de curso.
   */
  getExpiredReservations(
    headquarterCode: string,
    courseCode: string
  ): Promise<ReservationModel[]> {
    const nowMinusConfiguredMinutes = moment(new Date())
      .subtract(ReservationService.EXPIRED_MINUTES, 'minutes')
      .toDate();
    const filter = `courseCode=${courseCode}&headquarterCode=${headquarterCode}&discarded=false&createdAt=${nowMinusConfiguredMinutes.toISOString()}`;
    return this.httpClient
      .getRequest<ReservationModel[]>(
        `${this.API_PATH}/${this.ENTITY_PATH}/find-expired?${filter}`
      )
      .toPromise();
  }

  /**
   * Realizar registro de interés en curso y reserva de la configuración solicitada.
   * Si es una modificación de la reserva actualiza el modelo actual.
   *
   * @param reserva datos de la reserva para el curso elegido.
   */
  registerCourseInterestAndReservation(
    reserva: ReservationModel
  ): Promise<void> {
    reserva.urlOrigin = this.getUrlOrigin();
    reserva.urlProviderOrigin = this.getUrlProviderOrigin();
    return this.httpClient
      .patchRequest<void>(
        `${this.API_PATH}/${this.ENTITY_PATH}/register-course-interest-and-reservation`,
        reserva
      )
      .toPromise();
  }

  /**
   * Descarta la reserva de las plazas según el código de curso, sede, identificador de sesión
   * e identificador de orderId.
   *
   * @param reservationDiscard modelo con los datos de la reserva para descartala.
   */
  discardReservation(
    reservationDiscard: ReservationDiscardModel
  ): Promise<void> {
    return this.httpClient
      .patchRequest<void>(
        `${this.API_PATH}/${this.ENTITY_PATH}/discard-course-interest-and-reservation`,
        reservationDiscard
      )
      .toPromise();
  }

  /**
   * Actualiza el modelo de reserva para confirmar el pago satisfactorio.
   *
   * @param orderId identificador del pedido.
   */
  confirmCourseInterestAndReservation(
    orderId: string
  ): Promise<ReservationModel> {
    return this.httpClient
      .patchRequest<ReservationModel>(
        `${this.API_PATH}/${this.ENTITY_PATH}/order/${orderId}/confirm`,
        null
      )
      .toPromise();
  }

    /**
   * Trae la reserva por orderId.
   *
   * @param orderId identificador del pedido.
   */
    getReservationByOrderId(
      orderId: string
    ): Promise<ReservationModel> {
      return this.httpClient
        .getRequest<ReservationModel>(
          `${this.API_PATH}/${this.ENTITY_PATH}/order/${orderId}`)
        .toPromise();
    }

  public getUrlOrigin(): string {
    return this.urlOrigin$.getValue()
  }
  public setUrlOrigin(urlOrigin: string): void{
    this.urlOrigin$.next(urlOrigin)
  }

  public getUrlProviderOrigin(): string {
    return this.urlProviderOrigin$.getValue()
  }
  public setUrlProviderOrigin(urlProviderOrigin: string): void {
    this.urlProviderOrigin$.next(urlProviderOrigin)
  }
}
