import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HttpModule } from './http/http.module';
import { SidebarModule } from './components/sidebar/sidebar.module';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { JWTTokenService } from './services/jwt-token.service';
import { LocalStorageService } from './services/local-storage.service';
import { SidebarService } from './services/sidebar.service';
import { JwtTokenUserPayloadService } from './services/jwt-token-user-payload.service';


@NgModule({
	imports: [
		CommonModule,
		HttpModule,
		SidebarModule
	],
	exports: [
		HttpModule,
		SidebarModule
	],
	providers: [
		AuthGuardService,
		AuthService,
		JWTTokenService,
		LocalStorageService,
		SidebarService,
		JwtTokenUserPayloadService
	]
})
export class CoreModule { }