import { Injectable } from '@angular/core';
import jwt_decode, { } from 'jwt-decode';

/**
 * Este servicio proporciona métodos de control en base al token JWT.
 */
@Injectable()
export class JWTTokenService {

	constructor() { }

	/**
	 * Decodifica el token para poder obtener el payload.
	 * @param jwtToken literal del token.
	 */
	private decodeToken(jwtToken: string): { [key: string]: string } {
		return jwt_decode(jwtToken);
	}

	/**
	 * Obtener tiempo de expiración del token.
	 * @param jwtToken literal del token.
	 */
	private getExpiryTime(jwtToken: string) {
		const decodedToken = this.decodeToken(jwtToken);
		return decodedToken ? decodedToken.exp : null;
	}

	/**
	 * Obtener el código de role del usuario.
	 * @param jwtToken literal del token.
	 */
	getRole(jwtToken: string): string | null {
		const decodedToken = this.decodeToken(jwtToken);
		return decodedToken ? decodedToken.role : null;
	}

	/**
	 * Obtener el identificador único del usuario.
	 * @param jwtToken literal del token.
	 */
	getUserId(jwtToken: string): string | null {
		const decodedToken = this.decodeToken(jwtToken);
		return decodedToken ? decodedToken.id : null;
	}

	/**
	 * Obtener código de lenguage del usuario.
	 * @param jwtToken literal del token.
	 */
	getUserLang(jwtToken: string): string | null {
		const decodedToken = this.decodeToken(jwtToken);
		return decodedToken ? decodedToken.lang : null;
	}

	/**
	 * Indica si un token está expirado por tiempo.
	 * @param jwtToken literal del token.
	 */
	isTokenExpired(jwtToken: string): boolean {
		const expiryTime: string | null = this.getExpiryTime(jwtToken);
		if (expiryTime) {
			return ((1000 * Number.parseInt(expiryTime)) < (new Date()).getTime());
		} else {
			return false;
		}
	}
}