import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Se exportan las rutas para la realización correcta de los tests.
export const routes: Routes = [
	{ path: 'wizard', loadChildren: () => import('./module/contracting-wizard/contracting-wizard.module').then(module => module.ContractingWizardModule) },
	{ path: '', redirectTo: 'wizard', pathMatch: 'full' },
	{ path: '**', redirectTo: 'wizard' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
