import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JWTTokenService } from './jwt-token.service';
import { LocalStorageService } from './local-storage.service';

/**
 * Este servicio se encarga de proporcionar métodos de acceso al token de seguridad JWT
 * y de control de autenticación.
 */
@Injectable()
export class AuthService {

	constructor(private jwtTokenService: JWTTokenService, private storageService: LocalStorageService, public router: Router) { }

	/**
	 * Este método comprueba si hay un token JWT almacenado y sigue siendo válido.
	 */
	isAuthenticated(): boolean {
		const token: string | null = this.getJWTToken();
		return token != null && !this.jwtTokenService.isTokenExpired(token);
	}

	/**
	 * Este método obtiene el token JWT almacenado.
	 */
	getJWTToken(): string | null {
		return this.storageService.get(LocalStorageService.JWT_TOKEN_KEY);
	}

	/**
	 * Este método almacena el token en la memoría del navegador local.
	 * @param token literal del token.
	 */
	setJWTToken(token: string): void {
		this.storageService.set(LocalStorageService.JWT_TOKEN_KEY, token);
	}
}