import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
import { SidebarService } from './sidebar.service';

/**
 * Este servicio se encarga de controlar si las rutas se pueden activar "navegar hacia"
 * en base a la autenticación del usuario.
 */
@Injectable()
export class AuthGuardService implements CanActivate {

	constructor(public auth: AuthService, public router: Router) { }

	/**
	 * Este método determina si una ruta puede ser accesible en base a la autenticación
	 * por token JWT.
	 * Se notifica a la sidebar de que puede mostrarse, y elementos del header, por si se
	 * entra a una ruta directamente sin pasar por login y existe un token válido. Así, el
	 * AuthService muestra elementos al iniciar sesión y los oculta al cerrar sesión, y el
	 * guardián de rutas puede mostrar los elementos al entrar en modo directo sin pasar por
	 * login.
	 */
	canActivate(): boolean {
		if (!this.auth.isAuthenticated()) {
			return false;
		}

		return true;
	}
}