import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError, delay, retryWhen, take } from 'rxjs/operators';

/**
 * Este servicio intercepta las llamadas http de la aplicación para inyectar la variable
 * del token de seguridad JWT, y comprobar las respuestas del servidor.
 */
@Injectable()
export class AppInterceptor implements HttpInterceptor {

	constructor(private authService: AuthService) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token = this.authService.getJWTToken();
		req = req.clone({
			url: req.url,
			setHeaders: {
				Authorization: `Bearer ${token}`
			}
		});

		return next.handle(req)
			.pipe(
				/*catchError((error: HttpErrorResponse) => {
					if (error.error instanceof ErrorEvent) {
						// client-side error
					} else {
						// server-side error
					}
					return throwError(error);
				}),*/
				retryWhen(errors => errors.pipe(delay(1000), take(2)))
			);
	}
}