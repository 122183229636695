import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../module/material/material.module';
import { RangePipe } from './pipe/range-pipe';
import { CustomDateAdapter } from './material/custom-date/custom-date-adapter';
import { DateAdapter } from '@angular/material/core';


@NgModule({
	imports: [
		CommonModule,
		MaterialModule
	],
	declarations: [
		RangePipe
	],
	exports: [
		RangePipe
	],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
  ]
})
export class SharedModule { }
