import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from 'src/app/module/material/material.module';

import { AppRoutingModule } from 'src/app/app-routing.module';
import { SidebarComponent } from './components/sidebar/sidebar.component';

@NgModule({
	declarations: [
		SidebarComponent
	],
	imports: [
		CommonModule,
		MaterialModule,
		AppRoutingModule
	],
	exports: [
		SidebarComponent
	]
})
export class SidebarModule { }