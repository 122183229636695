import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { JWTTokenService } from "./jwt-token.service";

/**
 * Este servicio proporciona métodos de acceso a datos del payload relativos al usuario autenticado
 * a través del token JWT.
 */
@Injectable()
export class JwtTokenUserPayloadService {

	constructor(private jwtTokenService: JWTTokenService, private authService: AuthService) { }


	/**
	 * Obtener el código de role del usuario del token válido almacenado.
	 */
	getTokenUserRole(): string | null {
		const token: string | null = this.authService.getJWTToken();
		return token ? this.jwtTokenService.getRole(token) : null;
	}

	/**
	 * Obtener el identificador único del usuario del token válido almacenado.
	 */
	getTokenUserId(): number | undefined {
		const token: string | null = this.authService.getJWTToken();
		const idPayload: string | null = token ? this.jwtTokenService.getUserId(token) : null;
		return idPayload ? Number.parseFloat(idPayload) : undefined;
	}

	/**
	 * Obtener código de lenguage del usuario del token válido almacenado.
	 */
	getTokenUserLang(): string | null {
		const token: string | null = this.authService.getJWTToken();
		return token ? this.jwtTokenService.getUserLang(token) : null;
	}
}