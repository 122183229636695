<mat-sidenav-container>

  <mat-sidenav-content>
    <div class="main-content" id="sidenavMainContent">
      <router-outlet></router-outlet>
    </div>
    <div id="loadingOverlay">
      <div class="loading-overlay-content" i18n="loading overlay@@loadingOverlay">
        Cargando
      </div>
    </div>
  </mat-sidenav-content>

</mat-sidenav-container>

<div *ngIf="isShowingRouteLoadIndicator" class="router-load-indicator" i18n="module loader@@moduleLoader">
  Cargando, un momento
</div>
