export interface ReservationModel {
	id?: string;
	courseCode: string;
	headquarterCode: string;
	sessionId?: string;
	seats: number;
	orderId: string;
	createdAt?: Date;
	discarded?: boolean;
	paymentDone?: boolean;
  idSeatReserve?: string;
  idProduct?:string;
  urlOrigin?: string;
  urlProviderOrigin?: string;
}

export const URL_PROVIDER_ORIGINS = {
  GOOGLE: "google",
  META: "meta",
  OTROS: "otros",
  DIRECTO: "directo",
}

export const URL_PROVIDER_ORIGINS_SEARCHER = {
  GOOGLE: {
    SEARCH: "gclid",
    VALUE: URL_PROVIDER_ORIGINS.GOOGLE
  },
  META: {
    SEARCH: "fbclid",
    VALUE: URL_PROVIDER_ORIGINS.META
  }
}
