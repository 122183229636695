import { Injectable } from '@angular/core';

/**
 * Este servicio se encarga de manejar las propiedades de la aplicación.
 */
@Injectable()
export class LocalStorageService {
	/** Clave referente al ítem token JWT */
	static JWT_TOKEN_KEY: string = 'trustmelegal.alisios-crm-token';

	constructor() { }

	/**
	 * Almacenar un ítem en base a la clave proporcionada.
	 * @param key clave única del ítem.
	 * @param value ítem a almacenar.
	 */
	set(key: string, value: string) {
		localStorage.setItem(key, value);
	}

	/**
	 * Obtener un ítem en base a la clave proporcionada.
	 * @param key clave única del ítem.
	 */
	get(key: string) {
		return localStorage.getItem(key);
	}

	/**
	 * Eliminar un ítem en base a la clave proporcionada.
	 * @param key clave única del ítem.
	 */
	remove(key: string) {
		localStorage.removeItem(key);
	}
}