import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

/**
 * Clase para la internacionalización en español del elemento paginación de material.
 */
@Injectable()
export class MatPaginatorIntlEs extends MatPaginatorIntl {
	itemsPerPageLabel = 'Elementos por página';
	nextPageLabel = 'Siguiente página';
	previousPageLabel = 'Página anterior';
	firstPageLabel = 'Primera página';
	lastPageLabel = 'Última página';

	getRangeLabel = function (page: number, pageSize: number, length: number) {
		if (length === 0 || pageSize === 0) {
			return '0 de ' + length;
		}
		length = Math.max(length, 0);
		const startIndex = page * pageSize;
		// If the start index exceeds the list length, do not try and fix the end index to the end.
		const endIndex = startIndex < length ?
			Math.min(startIndex + pageSize, length) :
			startIndex + pageSize;
		return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
	};

}