import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpModule } from './http.module';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

/**
 * Este servicio simplifica las llamadas a la API con métodos CRUD llevando a cabo tareas
 * de unión de URL de la API y las rutas o capilares.
 */
@Injectable({
	providedIn: HttpModule
})
export class HttpClientWrapperService {

	constructor(private httpClient: HttpClient) { }

	/**
	 * Este método envía una petición GET a la URL especificada como variable de entorno y ruta
	 * que recibe como parámetro.
	 * El objeto recibido es tipado según la interfaz establecida en ResponseType.
	 * @param path ruta a la que atacar.
	 */
	public getRequest<ResponseType = Object>(path: string): Observable<ResponseType> {
    return this.httpClient.get<ResponseType>(environment.apiUrl + path);

   // return this.httpClient.get<ResponseType>(environment.apis.default.rootUrl + path);
	}

	/**
	 * Este método envía una petición GET a la URL especificada como variable de entorno y ruta
	 * que recibe como parámetro.
	 * El objeto recibido es tipado como objeto Blob.
	 * @param path ruta a la que atacar.
	 */
	public getRequestBlob(path: string): Observable<Blob> {
		return this.httpClient.get(environment.apiUrl + path, { responseType: 'blob' });
  //  return this.httpClient.get(environment.apis.default.rootUrl + path, { responseType: 'blob' });
	}

	/**
	 * Este método envía una petición POST a la URL especificada como variable de entorno y ruta
	 * que recibe como parámetro, adjuntando el contenido del cuerpo recibido por la variable BODY.
	 * El objeto recibido es tipado según la interfaz establecida en ResponseType.
	 * @param path ruta a la que atacar.
	 * @param body objeto con los datos a enviar.
	 */
	public postRequest<ResponseType = Object>(path: string, body: any): Observable<ResponseType> {
		return this.httpClient.post<ResponseType>(environment.apiUrl + path, body);
  //  return this.httpClient.post<ResponseType>(environment.apis.default.rootUrl + path, body);
	}

	/**
	 * Este método envía una petición PUT a la URL especificada como variable de entorno y ruta
	 * que recibe como parámetro, adjuntando el contenido del cuerpo recibido por la variable BODY.
	 * @param path ruta a la que atacar.
	 * @param body objeto con los datos a enviar.
	 */
	public putRequest<ResponseType = Object>(path: string, body: any): Observable<ResponseType> {
		return this.httpClient.put<ResponseType>(environment.apiUrl + path, body);
  //  return this.httpClient.put<ResponseType>(environment.apis.default.rootUrl + path, body);
	}

	/**
	 * Este método envía una petición DELETE a la URL especificada como variable de entorno y ruta
	 * que recibe como parámetro.
	 * @param path ruta a la que atacar.
	 */
	public deleteRequest<ResponseType = void>(path: string): Observable<ResponseType> {
		return this.httpClient.delete<ResponseType>(environment.apiUrl + path);
  //  return this.httpClient.delete<ResponseType>(environment.apis.default.rootUrl + path);
	}

	/**
	 * Este método envía una petición PATCH a la URL especificada como variable de entorno y ruta
	 * que recibe como parámetro, adjuntando el contenido del cuerpo recibido por la variable BODY.
	 * @param path ruta a la que atacar.
	 * @param body objeto con los datos a enviar.
	 */
	public patchRequest<ResponseType = void>(path: string, body: any): Observable<ResponseType> {
		return this.httpClient.patch<ResponseType>(environment.apiUrl + path, body);
   // return this.httpClient.patch<ResponseType>(environment.apis.default.rootUrl + path, body);
	}
}
